<template>
  <div class="pageWarpper">
    <van-nav-bar title="支付结果" border fixed safe-area-inset-top />
    <div class="container">
      <div class="result-card">
        <van-icon
          name="checked"
          :size="40"
          color="#00C88D"
          v-if="resultType === PAY_RESULT_ENUM.SUCCESS"
        />
        <van-icon name="clear" :size="40" color="red" v-else />
        <div class="result-text">{{ PAY_RESULT_TXT_ENUM[resultType] }}</div>
        <div class="result-amount">￥{{ amount }}</div>
        <van-cell-group>
          <van-cell title="支付方式：">微信</van-cell>
          <van-cell title="订单编号：">1100102929200515122222097432</van-cell>
          <van-cell title="支付时间：">2023-06-01 18:23:21</van-cell>
          <van-cell title="收款方:">熊猫加油</van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="footer">
      <van-button color="#FF9500" v-if="resultType === PAY_RESULT_ENUM.FAIL"
        >重新支付</van-button
      >
      <van-button color="#FF9500" v-else>订单详情</van-button>
    </div>
  </div>
</template>
<script>
const PAY_RESULT_ENUM = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

const PAY_RESULT_TXT_ENUM = {
  SUCCESS: "支付成功",
  FAIL: "支付失败",
};

export default {
  name: "CashierResult",
  data: () => {
    return {
      amount: "0",

      resultType: PAY_RESULT_ENUM.SUCCESS,
      PAY_RESULT_ENUM,
      PAY_RESULT_TXT_ENUM,
    };
  },

  computed: {
    getResultType() {
      if (
        [PAY_RESULT_ENUM.SUCCESS, PAY_RESULT_ENUM.FAIL].includes(
          this.$route.params.resultType.toLocaleUpperCase()
        )
      ) {
        return this.$route.params.resultType.toLocaleUpperCase();
      }
      return PAY_RESULT_ENUM.FAIL;
    },
  },
  mounted() {
    this.resultType = this.getResultType;
  },
};
</script>
<style lang="scss" scoped>
.pageWarpper {
  background: #f8f8f8;
  padding-top: 46px;
  font-family: PingFangSC-Regular, PingFang SC;
  height: calc(100vh - 46px);
}
.container {
  .result-card {
    padding: 30px;
    background: #ffffff;
    border-radius: 8px;
    margin: 20px 15px;
    .result-text {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
    }
    .result-amount {
      font-size: 26px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .van-cell__title {
      text-align: left;
    }
  }
}
</style>